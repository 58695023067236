<template>
  <v-layout class="ux-icon" v-if="checkHelpTextSettings">
    <div v-if="showHelpIcon">
      <F2gIcon
        class="mr-3"
        :height="iconHeight"
        :width="iconWidth"
        :stroke="iconColor"
        value="question-mark"
        @click.stop="showInfoMessage = !showInfoMessage"
      />
    </div>
    <div v-if="showInfoMessage || showHelpMessageFromOutside">
      <F2gCard
        class="helpCard"
        :helpMessage="message"
        :style="
          helpCardWidth
            ? `min-width: ${helpCardMinWidthpx}px !important; width: ${helpCardWidth}px !important; ${setStyle()} `
            : `min-width: ${helpCardMinWidthpx}px !important; width: 100% !important; ${setStyle()} `
        "
      >
        <div class="pa-3">
          <span class="message" v-html="message"></span>
        </div>
      </F2gCard>
    </div>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "uxIcon",

  props: {
    helpCardWidth: { type: Number, required: false, default: null },
    helpCardMinWidthpx: { type: Number, required: false, default: 0 },
    helpCardTopPixels: { type: Number, required: false },
    helpCardLeftPixels: { type: Number, required: false },
    iconHeight: { type: Number, required: false, default: 15 },
    iconWidth: { type: Number, required: false, default: 15 },
    iconColor: { type: String, required: false, default: "#202020" },
    isForm: {
      type: Boolean,
      required: false,
      default: false,
    },
    message: { type: String, required: true },
    showHelpMessageFromOutside: {
      type: Boolean,
      required: false,
      default: false,
    },
    showHelpIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  watch: {
    showInfoMessage() {
      if (this.showInfoMessage) {
        window.addEventListener(
          "click",
          () => (this.showInfoMessage = false),
          null
        );
      } else {
        window.removeEventListener("click", null, null);
      }
    },
  },
  data() {
    return {
      showInfoMessage: false,
    };
  },
  created() {
    if (this.showHelpMessageFromOutside) {
      setTimeout(
        () =>
          window.addEventListener(
            "click",
            () => this.closeWhenClickedAway(),
            null
          ),
        300
      );
    }
  },
  beforeDestroy() {
    if (this.showHelpMessageFromOutside) {
      window.removeEventListener("click", null, null);
    }
  },
  computed: {
    ...mapGetters({
      userProfile: "auth/getUserProfile",
    }),
    checkHelpTextSettings() {
      let showHelp = false;
      if (
        (this.userProfile.settings.showDetailHelpMessages &&
          this.userProfile.settings.showFormHelpMessages) ||
        (this.userProfile.settings.showFormHelpMessages && this.isForm)
      ) {
        showHelp = true;
      }
      return showHelp;
    },
  },
  methods: {
    setStyle() {
      return `top: ${this.helpCardTopPixels}px; left: ${this.helpCardLeftPixels}px;`;
    },
    closeWhenClickedAway() {
      this.$emit("clickedAway");
    },
  },
};
</script>

<style lang="scss" scoped>
.ux-icon {
  cursor: pointer;
  position: relative;
  line-height: 14px;
  font-size: 13px;
  font-weight: 400;

  .helpCard {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3), 0 0 10px rgba(0, 0, 0, 0.096) !important;
    position: absolute;
    z-index: 214748364;
    min-height: 30px !important;
    text-align: left;
    line-height: 1.2;
    text-justify: inter-word;
  }
}
</style>
